.w-block1{
    @apply max-w-[500px]
}
.title-level1{
    /* @apply font-extrabold text-[34px] uppercase leading-[44px] w-[80%%]  */
    @apply text-[35px] font-extrabold uppercase w-full leading-[42px]
}
.text-level1{
    /* @apply font-light text-[27px] uppercase */
    @apply text-[29px] font-light uppercase
}

.label-level2{
    @apply text-[19px] font-bold leading-[27px]
}
.title-level2{
    @apply  text-[30px] leading-[36px] font-bold
}
.label-level2{
    color: var(--bg-inverted);
}
.color-inverted{
    color: var(--bg-inverted);
}
.text-level2{
    @apply font-light text-[18px] leading-[25px]
}
.btn{
    background-color: #000;
    color: #fff;
}
.title-resource{
    @apply text-[14px] italic
}
.px-page{
    @apply px-[120px]
}
.pl-page{
    @apply pl-[120px]
}
.pr-page{
    @apply pr-[120px]
}
.pt-page{
    @apply pt-[157px]
}
.bg-block-inverted{
    background-color: #000;
    color: #fff;
  
}
.py-block{
    padding-top: 220px;
    padding-bottom: 220px;
}
.pb-block{
    padding-bottom: 220px;
}
.bg-block-inverted .title-level2, .bg-block-inverted .color-inverted, .bg-block-inverted .label-level2{
    color: inherit
}
.space-between-blocks{
    @apply space-y-[100px]
}
.space-between-same-block{
    @apply space-y-8
}
.space-between-main-blocks{
    @apply space-y-[120px]
}
.space-between-2cols{
    @apply gap-x-[30px]
}
.bg-container{
    background-color: #ededed;
}
.selected{
    position: relative;
}
.selected::after{
    content: '';
    width: 100%;
    width: 100%;
    height: 8px;
    background: #000;
    display: block;
    top: 47px;
    position: absolute;

}
